import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src477529347/src/autopatcher-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "adding-a-machine-to-autopatcher"
    }}>{`Adding a machine to AutoPatcher`}</h1>
    <p>{`A machine in AutoPatcher describes not just a machine, but also a set of parameters required to patch the machine, like what type of OS it runs, what category of patches are required and how can AutoPatcher access the machine.`}</p>
    <h2 {...{
      "id": "machine-types"
    }}>{`Machine types`}</h2>
    <p>{`There are two types of machines in AutoPatcher:`}</p>
    <ul>
      <li parentName="ul">{`AWS`}</li>
      <li parentName="ul">{`Hybrid`}</li>
    </ul>
    <h3 {...{
      "id": "aws-machines"
    }}>{`AWS machines`}</h3>
    <p>{`AWS machines are EC2 instances that are deployed in customer account. AutoPatcher can access them by assuming an IAM role which has to be deployed in the same account as the machine. More information about creating required permissions for EC2 machines can be found `}<a parentName="p" {...{
        "href": "/setting-up-ssm#iam-service-role-for-autopatcher-to-operate"
      }}>{`here`}</a>{`.`}</p>
    <h3 {...{
      "id": "hybrid-machines"
    }}>{`Hybrid machines`}</h3>
    <p><strong parentName="p">{`Hybrid machine`}</strong>{` is the term describing a machine other than AWS EC2. It can be any of:`}</p>
    <ul>
      <li parentName="ul">{`Azure`}</li>
      <li parentName="ul">{`GCP`}</li>
      <li parentName="ul">{`on-premise`}</li>
      <li parentName="ul"><em parentName="li">{`etc.`}</em></li>
    </ul>
    <p>{`Before a hybrid machine can be used by AutoPatcher the SSM Agent need to be installed on it. `}<a parentName="p" {...{
        "href": "/setting-up-ssm#setting-up-ssm-in-hybrid-mode"
      }}>{`Read more`}</a>{`.`}</p>
    <p>{`If the `}<a parentName="p" {...{
        "href": "/setting-up-ssm#automatic-installation"
      }}>{`automatic way`}</a>{` was chosen to install the SSM Agent no further action is required as AutoPatcher Installer tool automatically registers the machine in AutoPatcher.`}</p>
    <h2 {...{
      "id": "adding-a-machine"
    }}>{`Adding a machine`}</h2>
    <h3 {...{
      "id": "step-1"
    }}>{`Step 1`}</h3>
    <p>{`In the User Interface select from left navigation bar go to `}<strong parentName="p">{`Machines`}</strong>{` > `}<strong parentName="p">{`New Machine`}</strong></p>
    <p><img alt="adding-machine-1" src={require("../assets/adding-machine/ui-1.png")} /></p>
    <h3 {...{
      "id": "step-2"
    }}>{`Step 2`}</h3>
    <p>{`Choose the right path depending on the type of the machine which you want to add to AutoPatcher`}</p>
    <p><img alt="adding-machine-2" src={require("../assets/adding-machine/ui-2.png")} /></p>
    <h3 {...{
      "id": "step-31-for-aws-instances"
    }}>{`Step 3.1 (for AWS Instances)`}</h3>
    <p>{`Choose a name and provide correct SSM instance ID, IAM role ARN and select instance region:`}</p>
    <p><img alt="adding-machine-3" src={require("../assets/adding-machine/ui-3.png")} /></p>
    <h3 {...{
      "id": "step-32-for-hybrid-instances"
    }}>{`Step 3.2 (for Hybrid Instances)`}</h3>
    <p>{`First you need to activate the hybrid instance in SSM (read more `}<a parentName="p" {...{
        "href": "/setting-up-ssm#setting-up-ssm-in-hybrid-mode"
      }}>{`here`}</a>{`) and after that you'll get SSM Instance ID (with `}<strong parentName="p"><inlineCode parentName="strong">{`mi-`}</inlineCode></strong>{` prefix) required to register the machine`}</p>
    <p><img alt="adding-machine-4" src={require("../assets/adding-machine/ui-4.png")} /></p>
    <h3 {...{
      "id": "step-4-optional"
    }}>{`Step 4 (optional)`}</h3>
    <p>{`Here you can define additional attributes for the machine, such as description, OS type or `}<strong parentName="p">{`Allow reboot`}</strong></p>
    <p><img alt="adding-machine-5" src={require("../assets/adding-machine/ui-5.png")} /></p>
    <h4 {...{
      "id": "allow-reboot-checkbox-note"
    }}><em parentName="h4">{`Allow reboot`}</em>{` checkbox note`}</h4>
    <ul>
      <li parentName="ul">{`For Linux machines checked `}<strong parentName="li">{`Allow reboot`}</strong>{` means to `}<em parentName="li">{`always`}</em>{` reboot the machine after the updates are installed`}</li>
      <li parentName="ul">{`For Windows it means to allow SSM agent to decide whether to reboot the machine.`}</li>
    </ul>
    <p>{`If the checkbox is unchecked, the machine will not be rebooted after the patching.`}</p>
    <h3 {...{
      "id": "step-41-optional"
    }}>{`Step 4.1 (optional)`}</h3>
    <p>{`For Azure machines you can also provide Azure subscription id/name and resource group.`}</p>
    <p><img alt="adding-machine-6" src={require("../assets/adding-machine/ui-6.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      